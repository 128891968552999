@import 'src/styles/media';
@import 'src/styles/mixins';

.root {
  display: flex;
  flex-direction: column;
  background: var(--dark-bg-2);

  @include tablet-landscape {
    border-left: 24px solid var(--dark-bg-1);
    border-right: 24px solid var(--dark-bg-1);
  }
}

.main {
  @include page-appear;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 100%;
  min-height: calc(100dvh - var(--app-header-height) - var(--app-footer-height) - 40px);
  max-width: 1440px;
  margin: auto;
  width: 100%;
  padding-bottom: 96px;
}
