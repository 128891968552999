@import "src/styles/mixins";
@import "src/styles/media";

.root {
  height: var(--app-footer-height);
  padding: 0; // 40px 0;

  background: var(--dark-bg-1);

  display: none;

  @include tablet-landscape {
    display: flex;
  }
}

.wrapper {
  display: flex;
  align-items: flex-start;
  max-width: 1440px;
  margin: auto;
  width: 100%;
  flex-direction: column;
  padding: 0 16px 40px;
  gap: 40px;

  @include tablet-landscape {
    flex-direction: row;
    align-items: center;
    padding: 40px 74px;
    gap: 0;
  }
}

.content {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  width: 100%;
  gap: 40px;
  flex-direction: column;

  @include tablet-landscape {
    flex-direction: row;
    align-items: center;
  }
}

.links {
  display: flex;
  align-items: center;
  gap: 24px;

  a {
    &:hover {
      color: var(--green-1);
    }
  }
}

.socialLink {
  @include centralize;
  padding: 8px;
  color: var(--chakra-colors-whiteOpacity-50);

  border: 1px solid var(--chakra-colors-whiteOpacity-6);
  border-radius: 3px;
}

.socialInfo {
  padding: 8px 14px;
  color: var(--chakra-colors-whiteOpacity-50);
  background-color: var(--chakra-colors-whiteOpacity-6);;
}

.topicTitle {
  font-size: 14px;
  line-height: 20px;
  color: var(--chakra-colors-white);
}

.nav {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;

  > div {
    flex: 1;
  }
}

.listItem {
  margin: 16px 0;
  align-items: center;
  display: flex;
  white-space: nowrap;
  color: var(--chakra-colors-whiteOpacity-50);

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
  }

  > a {
    display: flex;
    align-items: center;
    color: var(--chakra-colors-whiteOpacity-50);

    svg:first-child {
      margin-right: 6px;
    }
  }
}

.link {
  display: flex;
  align-items: center;
  gap: 4px;
}
