@import 'src/styles/typography';

.caption {
  @extend %caption3;
  text-align: left;
  width: 100%;
  color: var(--gray-0);
  margin-bottom: 6px;
}

.inputWrapper {
  border-radius: 2px;
  background: #171816;
  padding: 12px;
  border: 1px solid transparent;
  transition: border-color 0.2s ease;

  &:focus-within {
    border-color: var(--gray-4);
  }
}

.tokenPriceInfo {
  text-align: left;
  width: 100%;
}

.balanceLabel {
  @extend %caption1;
  color: var(--gray-1);
}

.error {
  @extend %caption2;
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--system-error);
  width: 100%;
}
