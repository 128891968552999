@import 'src/styles/typography';

.root.root {
  @extend %body4;
  width: 100%;
  border-radius: 8px;
  background: var(--brand-gradient);
  color: var(--black);
  height: 48px;
  padding: 8px 24px;
}
