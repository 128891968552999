@import "src/styles/media";

.root.root {
  @include tablet-landscape {
    padding: 0;
  }

  background: rgb(15 15 15 / 50%);
  backdrop-filter: blur(50px);
  height: var(--app-header-height);
  left: 0;
  position: sticky;
  top: 0;
  width: 100%;
  margin: 0 auto;
  z-index: 90;
  padding: 0;
}

.wrapper.wrapper {
  @include tablet-landscape {
    max-width: 1440px;
    margin: auto;
    width: 100%;
    padding: 18px 74px;
  }

  margin: 0 auto;
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  position: relative;
  padding: 8px 16px;
  gap: 24px;
  border-top: 0;
}

.logo {
  align-items: center;
  display: flex;

  height: 100%;
  justify-content: flex-start;

  > img {
    height: 37px;
    width: 147px;
    min-width: 147px;
  }
}

.desktop {
  display: none;

  @include tablet-landscape {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: flex-end;
  }
}

