@import 'src/styles/typography';

.value {
  @extend %caption2;
  color: var(--white);
}

.error {
  color: var(--system-error);
}
