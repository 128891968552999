@import 'src/styles/typography';

.rowValue {
  @extend %caption2;
  color: var(--white);
}

.rowLabel {
  @extend %caption1;
  color: var(--gray-1);
}

.tooltip {
  padding: 12px;
  border-radius: 4px;
  background: var(--gray-5);
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 200px;
}

.tooltipRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2px;
}

.tooltipTitle {
  @extend %caption2;
  color: var(--white);
}

.tooltipBody {
  @extend %caption1;
  color: var(--gray-0);
}
