@import 'src/styles/typography';

.button.button {
  @extend %body2;
  border-radius: 8px;
  background: var(--brand-gradient);
  color: var(--black);
  height: 48px;
  padding: 8px 24px;
}
