.root {
  align-items: center;
  display: flex;
  flex: 1;
  width: 100%;
}

.wrapper {
  align-items: center;
  display: flex;
  border-radius: 4px;
  border: 1px solid var(--gray-4);
  padding: 0 12px;
  width: 100%;
  height: 40px;
  background-color: var(--dark-bg-1);

  input {
    flex: 1;
    width: 100%;
    background-color: var(--dark-bg-1);
  }
}

.iconWrapper {
  color: var(--gray-0);
}
