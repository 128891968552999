@import 'src/styles/typography';

.title {
  @extend %heading5;
}

.header {
  border-bottom: 1px solid var(--dark-bg-2);
}

.search {
  padding: 24px 40px;
  border-bottom: 1px solid var(--dark-bg-2);
  width: 100%;
}

.symbol {
  @extend %body3;
  color: var(--white);
}

.name {
  @extend %body3;
  color: #D7D9DD;
}
