@import "src/styles/mixins";
@import "src/styles/typography";

.scrollWrapper {
  @include custom-scrollbar-vertical;
}

.tokenIconWrapper {
  @include centralize;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: var(--gray-5);
}

.tokenSymbol {
  @extend %body4;
  color: var(--gray-0);
}

.selectBtn {
  @include centralize;
  width: 18px;
  height: 18px;
  border-radius: 8px;
  background: var(--brand-gradient);
}
