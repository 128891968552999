@import 'src/styles/media';
@import 'src/styles/typography';

.container {
  @include tablet-landscape {
    display: none;
  }

  position: fixed;
  bottom: 0;
  width: 100vw;
  z-index: 100;
}

.root {
  position: relative;
  width: 100vw;
  background: var(--dark-bg-1);
  padding: 16px 16px 24px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  z-index: 99;

  h3 {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 21px !important;
  }

}

.panel {
  width: 100vw;
  position: absolute;
  height: fit-content;
  z-index: 98;
  background: var(--dark-bg-1);
  box-shadow: 0 -8px 24px 6px rgb(18 20 18 / 80%);
  padding: 16px;

  display: flex;
  flex-direction: column;
  gap: 14px;
}

.overlay {
  backdrop-filter: blur(1px);
  background-color: rgb(0 0 0 / 50%);
  inset: 0;
  position: fixed;
  z-index: 90;
}

.divider {
  height: 1px;
  width: 100%;
  background: var(--dark-bg-2);
}

.menuItem {
  @extend %caption2;
  padding: 10px 0;
  display: flex;
  align-items: center;
  gap: 12px;

  color: var(--gray-1);
}

.link {
  color: var(--gray-0);
}
