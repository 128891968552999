@import '@styles/mixins';
@import '@styles/media';
@import '@styles/typography';

.root {
  display: flex;
  border-radius: 0;
  overflow: hidden;
  padding: 16px;
  align-items: center;
  gap: 16px;

  @include tablet-landscape {
    border-radius: 8px;
  }

  &.success {
    border: 1px solid var(--system-success);
  }

  &.warning {
    border: 1px solid var(--system-warning);
  }

  &.error {
    border: 1px solid var(--system-error);
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;

  &.info {
    background: var(--chakra-colors-neutral-700);
    color: var(--chakra-colors-white);
  }

  &.warning {
    background: var(--chakra-colors-danger-200);
    color: var(--chakra-colors-black);
  }

  &.error {
    background: var(--chakra-colors-orange);
    color: var(--chakra-colors-white);
  }
}

.icon {
  height: 24px;
  width: 24px;
  min-width: 24px;
}

.titleWrapper {
  display: flex;
  align-items: center;
}

.body.body {
  @extend %body3;
  color: var(--white);
  align-items: flex-start;
}

.closeBtn.closeBtn {
  min-width: unset;
  padding: 0;
}
