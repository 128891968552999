@import 'src/styles/typography';

.input {
  @extend %body1;
  color: var(--white);
  background: transparent;
  outline: none;
}

.error {
  color: var(--system-error);
}
