@import 'src/styles/typography';

.title {
  @extend %body1;
  padding: 0 16px;
  position: relative;

  &.active {
    color: var(--green-1);

    .indicator {
      width: 12px;
      height: 4px;
      position: absolute;
      background: var(--brand-gradient);
      border-radius: 8px;
      left: 50%;
      transform: translateX(-50%);
      top: calc(100% + 4px);
    }
  }
}
