@import 'src/styles/typography';
@import 'src/styles/media';

.control {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  border-radius: 6px;
  position: relative;
}

.menuItem {
  @extend %body3;
  color: var(--white);

  &.warning {
    color: var(--system-warning);
  }
}

.menu {
  width: 100vw;
  height: calc(100dvh - var(--app-header-height));
  border: none;

  background: var(--dark-bg-1);

  z-index: 110;

  @include tablet-landscape {
    border-radius: 8px;
    min-width: 300px;
    width: unset;
    overflow: hidden;
    border: 1px solid var(--gradient-shade);
    height: fit-content;
  }
}

.desktop {
  display: none;

  @include tablet-landscape {
    display: block;
  }
}

.warningWrapper {
  position: absolute;
  right: -14px;
  top: -12px;

  > svg {
    scale: 0.8;
  }

  @include tablet-landscape {
    position: relative;
    right: unset;
    top: unset;

    > svg {
      scale: 1;
    }
  }
}
