@import 'src/styles/typography';
@import 'src/styles/mixins';

.title {
  @extend %heading5;
  color: var(--gray-0);
  white-space: nowrap;
}

.header {
  padding: 24px 40px 12px;
  border-bottom: 1px solid var(--dark-bg-2);
}

.body {
  padding: 24px 40px;
  width: 100%;
  gap: 0;
  border-bottom: 1px solid var(--dark-bg-2);
}

.info {
  padding: 24px 40px;
  width: 100%;
  gap: 0;
  border-bottom: 1px solid var(--dark-bg-2);
}

.footer {
  padding: 24px 40px 40px;
  width: 100%;
  gap: 0;
}

.caption {
  @extend %caption2;
  color: var(--white);
  margin-bottom: 14px;
}

.content {
  width: 100%;
  padding: 24px 40px;
}

.divider {
  height: 1px;
  width: 100%;
  background: var(--dark-bg-2);
}

.share {
  @extend %body4;
  color: var(--green-1);
}


.label {
  @extend %caption1;
  color: var(--gray-1);
}

.value {
  @extend %body3;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}

.pointer {
  @include centralize;
  width: 100%;
  margin: 8px 0;
}

.outputWrapper {
  border-radius: 2px;
  padding: 8px 12px;
  background: var(--input-bg);

  display: flex;
  gap: 16px;
  flex-direction: column;

  .row {
    @extend %body1;
    color: var(--white);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
  }
}

