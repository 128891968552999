@import 'src/styles/typography';

.root {
  @extend %body3;
  display: flex;
  align-items: center;
  gap: 12px;

  .indicator {
    background: var(--gray-0);
    height: 6px;
    width: 6px;
    border-radius: 50%;
  }

  .label {
    color: var(--gray-0);
  }

  &.connected {
    .indicator {
      background: var(--green-1);
    }

    .label {
      color: var(--green-1);
    }
  }
}
