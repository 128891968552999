@import 'src/styles/typography';

.panel {
  border-radius: 8px;
  background: var(--dark-bg-1);
  border: 1px solid var(--green-2);
  box-shadow: 0 8px 24px 6px rgb(18 20 18 / 80%);

  padding: 12px 16px;

  z-index: 1;
}

.block {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.blockTitle {
  @extend %caption1;
  margin-bottom: 10px;
}

.divider {
  height: 1px;
  width: 100%;
  background: var(--dark-bg-2);
  margin: 12px 0;
}

.suffix {
  @extend %body3;
  color: var(--gray-0);
}
